import pic1 from "./images/photo2.jpeg";
import spices from "./images/spices.jpg";
import papad from "./images/papad.jpg";
import soanPapdi from "./images/soanPapdi.jpeg";

export const ProductDetails = {
  tagLine: [
    "Cruncy and Spicy",
    "Tempting and Delecious",
    "Hygienic and Fresh",
    "Aromatic and Pure",
  ],
  category: ["Namkeen", "Sweets", "Papad and Mangodi", "Tea and Spices"],
  description: [
    "Indulge in the savory flavors of tradition with the perfect blend of spice and crunch in every bite of our namkeen.",
    "Treat your taste buds to the heavenly sweetness & delicate texture of our premium quality soanpapdi and gajak",
    "Experience the authentic taste of Rajasthan with every bite of our papad and magodi",
    "Sip into serenity with every cup of our tea.\n Add a touch of magic to your meals with Madhu's aromatic spices.",
  ],
  link: ["/namkeen", "/sweets", "/papadMangodi", "/teaSpices"],
  image: [pic1, soanPapdi, papad, spices],
};
